<template>
  <div class="wrapper">
    <div class="login-wrapper">
      <el-card header="登录">
        <el-form
          ref="loginForm"
          :model="form"
          :rules="rules"
          @submit.native.prevent
          size="default"
        >
          <el-form-item prop="username">
            <el-input
              type="text"
              placeholder="请输入账号..."
              v-model="form.username"
              prefix-icon="el-icon-user"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              type="password"
              placeholder="请输入密码..."
              v-model="form.password"
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              class="login-button"
              native-type="submit"
              :loading="loading"
              @click="handleLogin('loginForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
        <!-- <div class="login-actions">
          <el-link type="info" :underline="false">新用户注册</el-link>
          <el-link type="info" :underline="false">忘记密码</el-link>
        </div> -->
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        grant_type: "password",
        client_id: "fit",
        client_secret: "123456",
        username: "",
        password: "",
        scope: "fit_admin",
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    handleLogin(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$api.auth
            .login(this.form)
            .then((res) => {
              this.$store.commit("SAVE_ACCESS_TOKEN", res);
              this.$router.push("/");
            })
            .catch(() => {
              this.$message({
                showClose: true,
                message: "用户名或密码错误",
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .login-wrapper {
    width: 500px;

    .login-button {
      width: 100%;
    }

    .login-actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
}
</style>
